<template>
  <div>
    <v-card tile class="mx-auto pa-4 text-center" v-if="large" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <v-icon size="200" color="appBar">mdi-alert-circle-outline</v-icon>
          <div class="overline mb-4">
            {{ $t("loadErrorOOPS") }}
          </div>
          <v-list-item-title class="headline mb-1">
            {{ $t("loadError") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions class="text-center">
        <v-spacer></v-spacer>
        <v-btn dark exact :to="{ name: 'home' }">
          {{ $t("error.goHome") }}</v-btn
        >
        <v-btn dark @click.stop="tryAgain()"> {{ $t("tryAgain") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-alert
      v-else
      prominent
      dark
      border="bottom"
      color="appBar"
      icon="mdi-alert-circle-outline"
      transition="scale-transition"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t("loadError") }}
        </v-col>
        <v-col class="shrink" v-if="storeAction">
          <v-btn text dark @click.stop="tryAgain()">
            {{ $t("tryAgain") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  },
  computed: {},
  props: {
    storeAction: {
      type: String,
      required: false,
    },
    storePayload: {
      type: [Object, Array, String, Number],
      required: false,
      default: null,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async tryAgain() {
      await this.$store.dispatch(this.storeAction, this.storePayload);
    },
  },
};
</script>
